export const useGlobalSettings = () => {
  const { locale } = useI18n()

  const prismic = usePrismic()
  return useAsyncData(
    '$global_settings',
    () => prismic.client.getSingle('global_settings', { lang: locale.value }),
    { watch: [locale] },
  ).data
}
